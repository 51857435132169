import { getId } from '@/utils';
import { Backlog, BacklogItemType } from './models';

export const defaultSettings = {};

export const defaultItemTypes = {
  Theme: {
    title: 'Theme',
    icon: 'mdi-movie-open',
    color: 'purple',
    childTypes: [BacklogItemType.Feature, BacklogItemType.Epic, BacklogItemType.Story],
  },
  Feature: {
    title: 'Feature',
    icon: 'mdi-trophy',
    color: 'green',
    childTypes: [BacklogItemType.Epic, BacklogItemType.Story],
  },
  Epic: {
    title: 'Epic',
    icon: 'mdi-book-multiple',
    color: 'amber',
    childTypes: [BacklogItemType.Story],
  },
  Story: {
    title: 'Story',
    icon: 'mdi-book-open-page-variant',
    color: 'blue',
    childTypes: [BacklogItemType.Task, BacklogItemType.Bug],
  },
  Task: { title: 'Task', icon: 'mdi-shield-check', color: 'blue-grey', childTypes: [] },
  Bug: { title: 'Bug', icon: 'mdi-shield-bug', color: 'red', childTypes: [] },
};

export const defaultBacklog = (): Backlog => ({
  id: getId(),
  code: 'BL',
  title: 'Backlog',
  status: 'Draft',
  //settings: defaultSettings,
  sequence: 0,
  items: [],
  events: [],
  owner: null,
  createdBy: null,
  createdOn: null,
});

/*
export const defaultIcons = {
  Theme: 'mdi-movie-open',
  Feature: 'mdi-trophy',
  Epic: 'mdi-book-multiple',
  Story: 'mdi-book-open-page-variant',
  Task: 'mdi-shield-check',
  Bug: 'mdi-shield-bug',
};
*/
