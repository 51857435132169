











































import Vue from 'vue';
import { formatDate } from '@/utils';
import { Backlog, Backlogs } from '../models';
import { backlogService } from '../services';

export default Vue.extend({
  name: 'BacklogsTable',

  props: {
    value: { type: Array as () => Backlogs, required: true },
  },

  methods: {
    formatDate,

    async cloneBacklog(backlog: Backlog) {
      try {
        await backlogService.cloneBacklog(backlog);
        this.$bus.$emit('snackbar-notify', 'Backlog successfully cloned', 'success');
      } catch (error) {
        this.$bus.$emit('snackbar-notify', error, 'error');
      }
    },
    async deleteSession(backlog: Backlog) {
      try {
        await backlogService.deleteBacklog(backlog.id);
        this.$bus.$emit('snackbar-notify', 'Backlog successfully deleted', 'success');
      } catch (error) {
        this.$bus.$emit('snackbar-notify', error, 'error');
      }
    },
  },
});
