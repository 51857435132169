import { firestore } from 'firebase/app';
import { db } from '@/firebase';
import { getId } from '@/utils';
import { Backlog, BacklogItemType } from './models';
import { defaultItemTypes } from './defaults';

export const backlogService = {
  // References
  getBacklogsRef() {
    return db.collection('backlogs');
  },
  getBacklogRef(id: string) {
    return db.collection('backlogs').doc(id);
  },
  getConfigRef() {
    return db.collection('admin').doc('backlogs');
  },
  // Backlog
  getAllBacklogs() {
    return this.getBacklogsRef();
  },
  getOwnBacklogs(userId: string) {
    return this.getBacklogsRef().where('owner', '==', userId);
  },
  async createBacklog(backlog: Backlog) {
    await this.getBacklogRef(backlog.id).set(backlog, { merge: true });
  },
  async updateBacklog(id: string, backlogData: firestore.UpdateData) {
    await this.getBacklogRef(id).update(backlogData);
  },
  async deleteBacklog(id: string) {
    await this.getBacklogRef(id).delete();
  },
  async cloneBacklog(backlog: Backlog) {
    const newBacklog: Backlog = { ...backlog };
    newBacklog.id = getId();
    newBacklog.status = 'Draft';
    newBacklog.events = [];
    newBacklog.createdOn = Date.now();
    newBacklog.updatedOn = null;
    await this.createBacklog(newBacklog);
  },
  getItemCode(backlog: Backlog, type: string): string {
    const backlogCode = backlog.code ? backlog.code + '-' : '';
    //const typeCode = type.substring(0, 1);
    const sequence = backlog.sequence;
    //const itemCode = `${backlogCode}${typeCode}${sequence}`;
    const itemCode = `${backlogCode}${sequence}`;
    return itemCode;
  },
};
