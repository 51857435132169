
































































import Vue from 'vue';
import BacklogsTable from '../components/BacklogsTable.vue';
import { Backlogs } from '../models';
import { backlogService } from '../services';

export default Vue.extend({
  name: 'BacklogsList',

  components: { BacklogsTable },

  data() {
    return {
      draftBacklogs: [] as Backlogs,
      activeBacklogs: [] as Backlogs,
      closedBacklogs: [] as Backlogs,
    };
  },

  computed: {
    userId(): string {
      return this.$store.getters['user/userId'];
    },
  },

  created() {
    this.$bind(
      'draftBacklogs',
      backlogService.getOwnBacklogs(this.userId).where('status', '==', 'Draft').orderBy('createdOn', 'desc')
    );
    this.$bind(
      'activeBacklogs',
      backlogService.getOwnBacklogs(this.userId).where('status', '==', 'Active').orderBy('createdOn', 'desc')
    );
    this.$bind(
      'closedBacklogs',
      backlogService.getOwnBacklogs(this.userId).where('status', '==', 'Closed').orderBy('createdOn', 'desc')
    );
  },

  mounted() {
    this.$bus.$emit('title-change', 'Backlogs', '/backlogs');
  },
});
