















































































































import Vue from 'vue';
import { User } from '@/models';
import { getStatusColor, getId } from '@/utils';
import { Backlog, BacklogItem, BacklogItems, BacklogItemType, BacklogItemTypes } from '../models';
import { backlogService } from '../services';
import { defaultItemTypes } from '../defaults';
import BacklogItemsHierarchy from '../components/BacklogItemsHierarchy.vue';

export default Vue.extend({
  name: 'BacklogView',

  components: { BacklogItemsHierarchy },

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      backlog: null as Backlog | null,
      showCreate: false,
      selectedItems: [] as BacklogItems,
      createdItem: null as { type: BacklogItemType; title: string; icon: string } | null,
      menuOpen: false,
      menuAttach: null,
      selectedItem: null as BacklogItem | null,
      defaultItemTypes,
    };
  },

  computed: {
    user(): User {
      return this.$store.getters['user/user'];
    },
    userId(): string {
      return this.$store.getters['user/userId'];
    },
    isOwner(): boolean {
      return this.backlog ? this.backlog.owner === this.userId : false;
    },
    selectedItem2(): BacklogItem | null {
      return this.selectedItems.length ? this.selectedItems[0] : null;
    },
    itemTypes(): BacklogItemTypes {
      if (!this.selectedItem) return [];
      console.log(this.selectedItem);
      return defaultItemTypes[this.selectedItem.type].childTypes;
    },
  },

  watch: {
    id: {
      immediate: true,
      async handler(id) {
        this.$bus.$emit('loading-indicator', true);
        await this.$bind('backlog', backlogService.getBacklogRef(id));
      },
    },
  },

  created() {
    this.$bus.$off('backlog-item-select').$on('backlog-item-select', this.onItemSelect);
    this.$bus.$off('backlog-item-create').$on('backlog-item-create', this.onItemCreate);
    this.$bus.$off('backlog-item-update').$on('backlog-item-update', this.onItemUpdate);
    this.$bus.$off('backlog-item-delete').$on('backlog-item-delete', this.onItemDelete);
  },

  mounted() {
    this.$bus.$emit('title-change', 'Backlogs', '/backlogs');
    this.$bus.$emit('loading-indicator', false);
  },

  methods: {
    getStatusColor,

    openTypeSelector(event, item: BacklogItem) {
      const activator = event.target.closest('.v-icon');
      console.log('Activator', activator);
      this.menuOpen = true;
      this.menuAttach = activator;

      const show = () => {
        this.menuOpen = true;
        this.menuAttach = activator;
      };
      const hide = () => {
        this.menuOpen = false;
        this.menuAttach = null;
      };
      const handle = () => {
        if (this.menuAttach === activator) {
          hide();
        } else {
          setTimeout(show, this.menuOpen ? 51 : 0);
        }
      };
      //this.$nextTick(handle);
    },
    isSelected(item: BacklogItem) {
      return item === this.selectedItem;
    },
    selectItem(item: BacklogItem) {
      //const item = items.length > 0 ? items[0] : null;
      //this.selectedItem = item;
      //this.menuOpen = true;
      //this.menuAttach = items[0];
      this.selectedItem = this.isSelected(item) ? null : item;
      console.log('Item selected', item);
    },
    getChildItemTypes(item: BacklogItem) {
      return defaultItemTypes[item.type].childTypes;
    },
    addChildItem(item: BacklogItem) {
      const type = item.type;
      console.log(type, defaultItemTypes[type]);
      this.createdItem = { type, title: '', icon: defaultItemTypes[type].icon };
    },
    createItem(itemType: BacklogItemType) {
      this.showCreate = true;
      this.createdItem = { type: itemType, title: '', icon: defaultItemTypes[itemType].icon };
    },
    async saveItem(itemTitle: string) {
      if (!this.createdItem) return;
      console.log('Save item', itemTitle);
      this.backlog!.sequence++;
      const item: BacklogItem = {
        id: getId(),
        code: backlogService.getItemCode(this.backlog!, this.createdItem.type),
        title: this.createdItem.title,
        type: this.createdItem.type,
        icon: this.createdItem.icon,
        status: 'Draft',
        items: [],
        createdBy: this.user,
        createdOn: Date.now(),
      };
      await this.addItem(item);
      this.showCreate = false;
    },
    async addItem(item: BacklogItem) {
      //this.selectedItem = this.selectedItems[0];
      if (this.selectedItem) {
        this.selectedItem.items.push(item);
      } else {
        this.backlog!.items.push(item);
      }
      this.selectedItems = [item];
      await backlogService.updateBacklog(this.backlog!.id, { ...this.backlog });
    },
    findParent(item: BacklogItem, parentItem: Backlog | BacklogItem): Backlog | BacklogItem | null {
      console.log('Item', item, 'Parent', parentItem);
      if (parentItem.items.includes(item)) return parentItem;
      parentItem.items.forEach((i) => this.findParent(item, i));
      return null;
    },
    async deleteItem(item: BacklogItem) {
      const parentItem = this.findParent(item, this.backlog!);
      console.log('Parent', parentItem);
      if (parentItem) {
        //parentItem.items = parentItem.items.filter((i) => i.id !== item.id);
        //this.$bus.$emit('snackbar-notify', 'Delete item ' + item.title, 'success');
        //await backlogService.updateBacklog(this.backlog!.id, { ...this.backlog });
      }
    },
    // Event handlers
    async onItemSelect(item: BacklogItem, parentItem: BacklogItem | null) {
      this.selectedItem = this.isSelected(item) ? null : item;
      console.log('Item selected', item, parentItem);
    },
    async onItemCreate(item: BacklogItem, type: BacklogItemType, parentItem: BacklogItem | null) {
      const parent = item || this.backlog;
      if (parent) {
        this.backlog!.sequence++;
        const itemType = defaultItemTypes[type];
        const newItem: BacklogItem = {
          id: getId(),
          code: backlogService.getItemCode(this.backlog!, itemType.title),
          title: itemType.title,
          type: type,
          icon: itemType.icon,
          status: 'New',
          items: [],
          createdBy: this.user,
          createdOn: Date.now(),
        };
        parent.items.push(newItem);
        console.log('Item created', item, parent);
        try {
          this.backlog!.updatedOn = Date.now();
          await backlogService.updateBacklog(this.backlog!.id, { ...this.backlog });
          this.$bus.$emit('snackbar-notify', 'Item successfully added to backlog', 'success');
        } catch (error) {
          this.$bus.$emit('snackbar-notify', error, 'error');
        }
      }
    },
    async onItemUpdate(item: BacklogItem, parentItem: BacklogItem | null) {
      console.log('Item updated', item, parentItem);
    },
    async onItemDelete(item: BacklogItem, parentItem: BacklogItem | null) {
      const parent = parentItem || this.backlog;
      if (parent) {
        const index = parent.items.findIndex((i) => i.id === item.id);
        parent.items.splice(index, 1);
        console.log('Item deleted', item, parent);
        try {
          this.backlog!.updatedOn = Date.now();
          await backlogService.updateBacklog(this.backlog!.id, { ...this.backlog });
          this.$bus.$emit('snackbar-notify', 'Item successfully deleted from backlog', 'success');
        } catch (error) {
          this.$bus.$emit('snackbar-notify', error, 'error');
        }
      }
    },
  },
});
