var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.backlog && _vm.backlog.status === 'Draft' && !_vm.isOwner)?_c('v-alert',{staticClass:"mx-3 my-6",attrs:{"dense":"","type":"error","colored-border":"","border":"bottom"}},[_vm._v(" This session is inactive ")]):(_vm.backlog)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',[_c('span',{staticClass:"text-h4",domProps:{"textContent":_vm._s(_vm.backlog.title)}})]),_c('v-col',{staticClass:"shrink"},[_c('v-chip',{staticClass:"elevation-1",attrs:{"small":"","label":"","dark":"","color":_vm.getStatusColor(_vm.backlog.status)},domProps:{"textContent":_vm._s(_vm.backlog.status)}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create new item ")],1)]}}])},_vm._l((_vm.defaultItemTypes),function(itemType,name){return _c('v-list',{key:name,attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();return _vm.createItem(name)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":itemType.color},domProps:{"textContent":_vm._s(itemType.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Create "+_vm._s(name)+" ")])],1)],1)],1)}),1)],1),(_vm.showCreate)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","solo":"","label":"Item title","prepend-inner-icon":_vm.createdItem.icon},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.saveItem($event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();_vm.showCreate = false}]},model:{value:(_vm.createdItem.title),callback:function ($$v) {_vm.$set(_vm.createdItem, "title", $$v)},expression:"createdItem.title"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('BacklogItemsHierarchy',{attrs:{"items":_vm.backlog.items,"selected":_vm.selectedItem,"disabled":false}})],1)],1),(false)?_c('v-row',[_c('v-col',[_c('v-treeview',{attrs:{"activatable":"","active":_vm.selectedItems,"dense":"","hoverable":"","open-all":"","return-object":"","selected-color":"secondary","items":_vm.backlog.items,"item-text":"title","item-children":"items"},on:{"update:active":[function($event){_vm.selectedItems=$event},_vm.selectItem]},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(_vm.isSelected(item))?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.openTypeSelector($event, item)}}},[_vm._v(" mdi-plus ")]):_vm._e(),_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(_vm.showCreate)?_c('span',[_vm._v("Edit")]):_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.isSelected(item))?_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,false,3885364303)})],1)],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","attach":_vm.menuAttach,"min-width":"180"},model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},_vm._l((_vm.itemTypes),function(itemType){return _c('v-list',{key:itemType.title,attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.createItem(itemType.title)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":itemType.color},domProps:{"textContent":_vm._s(itemType.icon)}})],1),_c('v-list-item-content',{staticClass:"text-left"},[_c('v-list-item-title',[_vm._v(" Create "+_vm._s(itemType)+" ")])],1)],1)],1)}),1),_c('v-btn',{attrs:{"fab":"","small":"","color":"accent","fixed":"","right":"","bottom":"","to":{ name: 'backlog-create' }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }