










import Vue from 'vue';
import BacklogEditor from '../components/BacklogEditor.vue';
import { backlogService } from '../services';

export default Vue.extend({
  name: 'BacklogCreate',

  components: { BacklogEditor },

  mounted() {
    this.$bus.$emit('title-change', 'Backlogs', '/backlogs');
  },

  methods: {
    async saveBacklog(backlog) {
      try {
        backlog.createdOn = Date.now();
        backlog.owner = this.$store.getters['user/userId'];
        await backlogService.createBacklog(backlog);
        this.$bus.$emit('snackbar-notify', 'Backlog successfully saved', 'success');
        this.$router.push({ name: 'backlogs-list' });
      } catch (error) {
        this.$bus.$emit('snackbar-notify', error, 'error');
      }
    },
  },
});
