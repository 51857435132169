














import Vue from 'vue';
import BacklogEditor from '../components/BacklogEditor.vue';
import { Backlog } from '../models';
import { backlogService } from '../services';

export default Vue.extend({
  name: 'BacklogEdit',

  components: { BacklogEditor },

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      backlog: null as Backlog | null,
    };
  },

  computed: {
    isOwner(): boolean {
      return this.backlog ? this.backlog.owner === this.$store.getters['user/userId'] : false;
    },
  },

  watch: {
    id: {
      immediate: true,
      async handler(id) {
        //this.$bus.$emit('loading-indicator', true);
        await this.$bind('backlog', backlogService.getBacklogRef(id));
      },
    },
  },

  mounted() {
    this.$bus.$emit('title-change', 'Backlogs', '/backlogs');
  },

  methods: {
    async saveBacklog(backlog) {
      try {
        backlog.updatedOn = Date.now();
        await backlogService.updateBacklog(backlog.id, backlog);
        this.$bus.$emit('snackbar-notify', 'Backlog successfully saved', 'success');
        this.$router.push({ name: 'backlogs-list' });
      } catch (error) {
        this.$bus.$emit('snackbar-notify', error, 'error');
      }
    },
    async deleteBacklog(backlog) {
      try {
        await backlogService.deleteBacklog(backlog.id);
        this.$bus.$emit('snackbar-notify', 'Backlog successfully deleted', 'success');
        this.$router.push({ name: 'backlogs-list' });
      } catch (error) {
        this.$bus.$emit('snackbar-notify', error, 'error');
      }
    },
  },
});
