













































import Vue from 'vue';
import BacklogItemNode from './BacklogItemNode.vue';
import { BacklogItem, BacklogItems, BacklogItemType } from '../models';
import { defaultItemTypes } from '../defaults';

export default Vue.extend({
  name: 'BacklogItemsHierarchy',

  props: {
    parent: {
      type: Object as () => BacklogItem,
      default: null,
    },
    items: {
      type: Array as () => BacklogItems,
      required: true,
    },
    selected: {
      type: Object as () => BacklogItem,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      itemTypes: defaultItemTypes,
    };
  },

  methods: {
    selectItem(item: BacklogItem) {
      this.$bus.$emit('backlog-item-select', item, this.parent);
    },
    addItem(item: BacklogItem, type: BacklogItemType) {
      this.$bus.$emit('backlog-item-create', item, type, this.parent);
    },
    editItem(item: BacklogItem) {
      this.$bus.$emit('backlog-item-update', item, this.parent);
    },
    deleteItem(item: BacklogItem) {
      this.$bus.$emit('backlog-item-delete', item, this.parent);
    },
  },
});
