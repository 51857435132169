

































































































import Vue from 'vue';
import { getStatusColor } from '@/utils';
import { Backlog } from '../models';
import { defaultBacklog } from '../defaults';
import { backlogService } from '../services';

export default Vue.extend({
  name: 'BacklogEditor',

  props: {
    value: {
      type: Object as () => Backlog,
      default: defaultBacklog,
    },
  },

  data() {
    return {
      backlog: this.value,
      valid: false,
    };
  },

  watch: {
    value: {
      deep: true,
      handler(backlog: Backlog) {
        this.backlog = backlog;
        if (backlog) {
          document.title = backlog.title;
          this.$bus.$emit('loading-indicator', false);
        }
      },
    },
  },

  created() {
    this.init();
  },

  mounted() {
    this.$bus.$emit('title-change', 'Backlogs', '/backlogs');
    this.$bus.$emit('loading-indicator', true);
  },

  methods: {
    getStatusColor,

    async init() {
      await this.$bind('config', backlogService.getConfigRef());
      this.$bus.$emit('loading-indicator', false);
    },

    saveBacklog() {
      this.$emit('save', this.backlog);
    },
    deleteBacklog() {
      this.$emit('delete', this.backlog);
    },

    setStatus(status: string) {
      this.backlog.status = status;
      if (status === 'Active') {
        //this.backlog.completedOn = null;
        //this.backlog.startedOn = Date.now();
      } else if (status === 'Closed') {
        //this.backlog.completedOn = Date.now();
      }
      this.$emit('save', this.backlog);
    },
  },
});
