//import type { Participant as BacklogParticipant, Participants as BacklogParticipants } from '@/models';

//export { BacklogParticipant, BacklogParticipants };

//export type BacklogSettings = {};

import type { User } from '@/models';
export { User };

export interface BacklogEvent {
  id: string;
  title: string;
  description?: string | null;
  timestamp?: number;
  status?: string;
  uid?: string;
  color?: string;
}
export type BacklogEvents = BacklogEvent[];

export enum BacklogItemType {
  Theme = 'Theme',
  Feature = 'Feature',
  Epic = 'Epic',
  Story = 'Story',
  Task = 'Task',
  Bug = 'Bug',
}
export interface BacklogItemTypeDef {
  icon: string;
  color: string;
  childTypes: string[];
}
//export type BacklogItemTypes = Record<BacklogItemType, BacklogItemTypeDef>;
export type BacklogItemTypes = BacklogItemType[];

export interface BacklogItemComment {
  title: string;
  description?: string | null;
  createdBy: User;
  createdOn: number;
  updatedBy?: User | null;
  updatedOn?: number | null;
}
export type BacklogItemComments = BacklogItemComment[];

export type BacklogItemTag = string;
export type BacklogItemTags = BacklogItemTag[];

export interface BacklogItem {
  id: string;
  code: string;
  title: string;
  description?: string | null;
  type: BacklogItemType;
  icon: string;
  items: BacklogItems;
  status: string;
  createdBy: User;
  createdOn: number;
  updatedBy?: User | null;
  updatedOn?: number | null;
  completedBy?: User | null;
  completedOn?: number | null;
  assignedTo?: User | null;
  assignedOn?: number | null;
  comments?: BacklogItemComments | null;
  tags?: BacklogItemTags | null;
}
export type BacklogItems = BacklogItem[];

export interface Backlog {
  id: string;
  code?: string;
  title: string;
  description?: string | null;
  status: string;
  //settings: BacklogSettings;
  sequence: number;
  items: BacklogItems;
  events: BacklogEvents;
  owner: string | null;
  createdBy: User | null;
  createdOn: number | null;
  updatedBy?: User | null;
  updatedOn?: number | null;
}
export type Backlogs = Backlog[];
